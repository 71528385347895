import React from 'react';
import { CDN_URL } from '@logsnag/constants/src/cdn';

export interface ILogSnagProviderProps {
  project: string;
  token: string;
}

/**
 * Set up the LogSnag provider
 * @param props: ILogSnagProviderProps
 * @constructor
 */
export const LogSnagProvider: React.FC<
  React.PropsWithChildren<ILogSnagProviderProps>
> = ({ project, token, children }) => {
  window.lsq = window.lsq || [];
  window.ls = window.ls || ((...args) => window.lsq?.push(args));
  React.useEffect(() => {
    // update the config with the project and token
    window.ls('setConfig', token, project);

    const script = document.createElement('script');
    script.id = 'logsnag-script';
    script.src = CDN_URL;
    script.async = true;

    // prevent multiple initializations
    if (!document.getElementById(script.id)) {
      document.body.appendChild(script);
    }
    return () => {
      document.body.removeChild(script);
    };
  }, [project, token]);
  return <>{children}</>;
};
