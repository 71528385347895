import { clearUserId, identify, setDebug, setUserId, track } from './funcs';

/**
 * LogSnag Hook
 * @description
 */
export const useLogSnag = () => {
  return {
    setDebug,
    setUserId,
    clearUserId,
    track,
    identify
  };
};
