import '@logsnag/web/types/gloabl';
import { TrackOptions } from '@logsnag/core';
import { IdentifyOptions } from '@logsnag/core';
import { PartialBy } from '@logsnag/web/types/tracker';

/**
 * Set the user id for the current user
 * if the user is not logged in, pass null
 * @description
 */
export const setUserId = (userId: string | null) => {
  window.ls('setUserId', userId);
};

/**
 * Clear the user id for the current user
 */
export const clearUserId = () => {
  window.ls('clearUserId');
};

/**
 * Set debug mode for logging
 */
export const setDebug = (flag: boolean = true) => {
  window.ls('setDebug', flag);
};

/**
 * Track custom events
 */
export const track = (options: TrackOptions) => {
  window.ls('track', options);
};

/**
 * Identify user traits
 */
export const identify = (options: PartialBy<IdentifyOptions, 'user_id'>) => {
  window.ls('identify', options);
};
